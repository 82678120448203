import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import RouterProvider from "./routes/routesProvider";
import { Provider } from "react-redux";
import { SuspenseLoader } from "app/shared/components";
import "toastr/build/toastr.min.css";
import store from "app/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<SuspenseLoader />}>
      <RouterProvider />
    </Suspense>
  </Provider>
);
