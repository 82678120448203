import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypePdf,
  BsFileEarmark,
  BsFiletypeJpg,
  BsFiletypePng,
} from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { Theme } from "app/shared/utils";
import { saveAs } from "file-saver";
import { useFile } from "app/shared/hooks";

const FileViewer = ({
  file,
  onDelete,
  disabled = false,
}) => {
  const { getExtension, convertBytesToMB } = useFile();
  const hasDeleteAction = !!onDelete;
  const fileSize = convertBytesToMB(file.file?.size||file.tamanho);
  const fileName = file.name||file.nome
  const fileUrl = file.url||file.preview
  const ExtensionIcon = (() => {
    const icons = {
      pdf: BsFiletypePdf,
      csv: BsFiletypeCsv,
      doc: BsFiletypeDoc,
      png: BsFiletypePng,
      jpg: BsFiletypeJpg,
    };
    let extension = getExtension(fileName);
    return icons?.[extension] || BsFileEarmark;
  })();

  const downloadFile = () => {
    saveAs(fileUrl, fileName);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete?.(file.id);
  };

  return (
    <Grid item justifyContent="center">
      <Stack onClick={downloadFile} sx={{cursor:'pointer'}}>
        <ExtensionIcon color={Theme.colors.black} size={50} />
      </Stack>
      {fileName && (
        <Tooltip title={fileName} arrow>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            variant="body2"
            maxWidth={65}
            mt="5px"
          >
            {fileName}
          </Typography>
        </Tooltip>
      )}
      <Typography color={Theme.colors.gray.dark} variant="caption">
        {fileSize}
      </Typography>

      {(hasDeleteAction && !disabled) && (
        <Grid container justifyContent="center">
          <IconButton onClick={handleDelete}>
            <FiTrash size={18} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default FileViewer;
