import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { setEscola } from "app/redux/reducers/globalSlice";
import { useService } from "app/shared/hooks";
import { buscaEscolas } from "app/services/escola";
import LoaderComponent from "../LoaderComponent";
import { formatParamFilter } from "app/shared/helpers";

const FiltroEscola = () => {
  const [todasEscolas, setTodasEscolas] = useState([]);
  const dispatch = useDispatch();
  const filtros = useSelector((state) => state.global.filtros);

  const handleLoadEscolas = (data) => {
    if (data) {
      setTodasEscolas(data);
    }
  };

  const {
    request: getEscolasReq,
    response,
    retry: retryBuscaEscolas,
  } = useService(buscaEscolas, true, handleLoadEscolas, () => { }, { cache: true, cacheKey: "getEscolas" });

  const hasGre = filtros.gre !== -1;
  const getEscolas = () => {
    if (!hasGre) return;
    getEscolasReq({
      ano: filtros.ano,
      si_id: formatParamFilter(filtros.gre),
      use_loja: 1,
      limit: 0,
      inc_si: 1,
      si_status: 1,
      escola_categoria: 1,
      status: 1,
    });
  };

  useEffect(() => {
    getEscolas();
  }, [filtros.ano, filtros.gre]);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retryBuscaEscolas}
        message="Buscando escolas"
        errorMessage="Falha ao carregar as escolas"
      >
        <FormControl fullWidth>
          <InputLabel shrink={true} id="escola-simple-select-label">
            Filtrar por escola
          </InputLabel>
          <Select
            labelId="escola-simple-select-label"
            id="escola-simple-select"
            value={filtros.escola || -1}
            size="small"
            label="Filtrar por escola"
            notched={true}
            disabled={!hasGre}
            displayEmpty
            onChange={(e) => {
              dispatch(setEscola(e.target.value));
            }}
          >
            <MenuItem value={-1}>
              Todas
            </MenuItem>
            {!!todasEscolas &&
              todasEscolas.map((gre) => {
                return (
                  <MenuItem key={gre.nome} value={gre.id}>
                    {gre.nome}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </LoaderComponent>
    </>
  );
};

export default FiltroEscola;
