import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import {
  FileViewer,
  ImagePreview,
  StyledAccordionSummary,
  ListaVazia,
} from "app/shared/components";
import { Theme } from "app/shared/utils";
import { FiChevronDown } from "react-icons/fi";

const AnexosSection = ({ anexos }) => {
  const tiposAnexo = {
    proposta: 0,
    notaFiscal: 1,
    fotos: 2,
    comprovanteCredito: 3,
  };

  const propostas = anexos.filter(
    (anexo) => anexo.tipo === tiposAnexo.proposta
  );
  const notaFiscais = anexos.filter(
    (anexo) => anexo.tipo === tiposAnexo.notaFiscal
  );
  const fotos = anexos.filter((anexo) => anexo.tipo === tiposAnexo.fotos);

  return (
    <>
      <Typography
        variant="h6"
        color={Theme.colors.blue.dark}
        fontWeight="bold"
        my="10px"
      >
        ANEXOS
      </Typography>
      <Grid item width="100%">
        <Accordion defaultExpanded>
          <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
            Propostas
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" gap={1}>
              <ListaVazia isListaVazia={propostas.length <= 0} />
              {propostas &&
                propostas.map((proposta) => (
                  <FileViewer
                    key={proposta.id}
                    file={proposta}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
            Nota fiscal
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" gap={1}>
              <ListaVazia isListaVazia={notaFiscais.length <= 0} />
              {notaFiscais &&
                notaFiscais.map((notaFiscal) => (
                  <FileViewer
                    key={notaFiscal.id}
                    file={notaFiscal}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
            Fotos
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" gap={1}>
              <ListaVazia isListaVazia={fotos.length <= 0} />
              {fotos &&
                fotos.map((foto) => (
                  <ImagePreview
                    key={foto.id}
                    size={75}
                    imageName={foto.nome}
                    url={foto.url}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

export default AnexosSection;
