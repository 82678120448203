import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import EdicaoCreditoForm from "./EdicaoCreditoForm";
import { GenericModal } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { alteraCreditoRecurso } from "app/services/recurso/post";
import { useEffect, useState } from "react";
import toastr from "toastr";
import { Theme } from "app/shared/utils";
import { getImageBlob } from "app/shared/helpers";
import { TipoAnexo } from "app/shared/constants";

const ModalEdicaoCredito = ({
  isMostrarModalEdicaoCredito,
  onClose,
  recurso,
  onSuccess = () => {},
  valorCreditoAtual = "0.00",
  currentAnexos = [],
}) => {
  const [files, setFiles] = useState([]);
  const [isLoadingAnexos, setLoadingAnexos] = useState(true);
  const [valorCredito, setValorCredito] = useState(valorCreditoAtual);

  const handleAlteraCreditoRecurso = (data) => {
    onSuccess();
    toastr.success("Crédito editado com sucesso!");
  };

  const {
    request: alteraCreditoRecursoReq,
    response: { loading },
  } = useService(alteraCreditoRecurso, false, handleAlteraCreditoRecurso);

  const downloadAnexos = async () => {
    setLoadingAnexos(true);
    try {
      const pormises = currentAnexos
        .filter((anexo) => anexo.tipo === TipoAnexo.COMPROVANTE_CREDITO)
        .map(async (anexo) => {
          return {
            file: await getImageBlob(anexo.url, anexo.nome),
            id: anexo.id,
            name: anexo.nome,
            preview: anexo.url,
            type: anexo.url.slice(-3),
            tipo: anexo.tipo,
          };
        });
      const anexosBlobs = await Promise.all(pormises);
      setFiles(anexosBlobs);
    } catch (error) {
    } finally {
      setLoadingAnexos(false);
    }
  };

  useEffect(() => {
    downloadAnexos();
  }, [currentAnexos]);

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("valor", valorCredito);
    formData.append("plano_id", recurso.id);

    if (files.length > 0) {
      const file = files[0];

      formData.append("anexo", file.file, file.name);

      alteraCreditoRecursoReq(formData).catch((error) => {
        toastr.warning(
          "Ocorreu um erro ao enviar o arquivo para edição de crédito"
        );
      });

      return;
    }

    alteraCreditoRecursoReq({
      valor: valorCredito,
      plano_id: recurso.id,
    });
  };

  const handleSetValorCredito = (event) => {
    const valorNumerico = parseFloat(event.target.value.replace(/,/g, "."));
    setValorCredito(
      isNaN(valorNumerico) || valorNumerico === 0 ? "0.00" : event.target.value
    );
  };

  const desabilitaSalvar =
    files.length <= 0 || valorCredito === null || isLoadingAnexos;

  return (
    <GenericModal
      open={isMostrarModalEdicaoCredito}
      onClose={onClose}
      size="xs"
      onSave={handleSubmit}
      isDisabledSave={desabilitaSalvar}
      isLoading={loading}
      preventCloseClickOutside
    >
      <Grid container>
        <Box width="100%">
          <Typography variant="h6" color="#59829d">
            EDIÇÃO DE CRÉDITO
          </Typography>
          <Typography
            variant="button"
            display="block"
            color={Theme.colors.gray.ligth}
          >
            ESCOLA: {recurso.escola.nome}
          </Typography>
          <Typography
            variant="button"
            display="block"
            color={Theme.colors.gray.ligth}
          >
            PLANO: {recurso.tipo_plano.descricao} &nbsp; &nbsp; ANO:{" "}
            {recurso.parcela.ano} &nbsp; &nbsp; PARCELA:{" "}
            {recurso.parcela.parcela}
          </Typography>
          <Divider style={{ marginBottom: 15, marginTop: 5 }} />
          <EdicaoCreditoForm
            setFiles={setFiles}
            currentFiles={files}
            loading={loading}
            setValorCredito={handleSetValorCredito}
            valorCredito={valorCredito}
          />
          {isLoadingAnexos && (
            <Box
              width="100%"
              justifyContent="center"
              display="flex"
              paddingY="10px"
            >
              <CircularProgress size={35} />
            </Box>
          )}
        </Box>
      </Grid>
    </GenericModal>
  );
};

export default ModalEdicaoCredito;
