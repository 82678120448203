import NavBarMobi from "@renatomobi/js-navbar-mobi/NavBarMobi";
import { AppKey } from "app/shared/constants/GlobalKeys";

const getDomainWithoutSubdomain = (url) => {
  const urlParts = url.hostname.split(".");

  if (urlParts.length < 4) {
    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join(".");
  }

  return url.hostname;
};

const DOMAIN = getDomainWithoutSubdomain(window.location);

export async function getUser() {
  try {
    const response = await NavBarMobi.getUserCookie(AppKey);
    if (response.status === "success") {
      return response.obj;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function loginMobi(result) {
  let user = { ...result };
  return await NavBarMobi.login(user, AppKey);
}

export function logout(saveLocation = false) {
  const currentPath = document.location.pathname + document.location.search;
  const prevUrlParam = saveLocation ? `?prevUrl=${currentPath}` : "";
  NavBarMobi.logout();
  window.location = "/auth" + prevUrlParam;
}
