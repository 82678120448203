import React from "react";
import { NumericFormat as NB } from "react-number-format";

const NumberFormat = React.forwardRef(function NumberFormat(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NB
      {...other}
      getInputRef={ref} // Usa ref aqui
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
    />
  );
});

export default NumberFormat;
