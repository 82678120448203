import React from "react";
import { NumericFormat as NB } from "react-number-format";

const QuantNumberFormat = React.forwardRef(function NumberFormat(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NB
      {...other}
      getInputRef={ref} // Usa ref aqui
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      allowLeadingZeros={false}
      allowNegative={false}
    />
  );
});

export default QuantNumberFormat;
