import { useEffect, useState } from "react";
import LoaderComponent from "../LoaderComponent";
import { useApp, useService } from "app/shared/hooks";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buscaParcelas } from "app/services/parcela";
import { setParcela } from "app/redux/reducers/globalSlice";
import { formatParamFilter } from "app/shared/helpers";

const FiltroParcela = () => {
  const filtros = useSelector((state) => state.global.filtros);
  const { ano } = useApp();
  const dispatch = useDispatch();
  const [parcelas, setParcelas] = useState([]);

  const handleLoadParcelas = (data) => {
    if (data) {
      setParcelas(data.data);
    }
  };

  const {
    request: getParcelasReq,
    response,
    retry: retryBuscaParcelas,
  } = useService(buscaParcelas, false, handleLoadParcelas, () => { }, { cache: true, cacheKey: "getParcela" });

  useEffect(() => {
    if (filtros.plano !== -1) {
      getParcelasReq({
        ano: ano,
        tipo_plano_id: formatParamFilter(filtros.plano),
      });
    }
  }, [filtros.plano, ano]);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retryBuscaParcelas}
        message="Buscando parcelas"
        errorMessage="Falha ao carregar as parcelas"
      />
      {!response.loading && (
        <FormControl fullWidth>
          <InputLabel shrink={true} id="demo-simple-select-label-parcela">
            Filtrar por parcela
          </InputLabel>
          <Select
            notched={true}
            displayEmpty
            labelId="demo-simple-select-label-parcela"
            id="demo-simple-select-parcela"
            value={filtros.parcela || -1}
            size="small"
            label="Filtrar por parcela"
            defaultValue={-1}
            disabled={filtros.plano === -1}
            onChange={(e) => {
              dispatch(setParcela(e.target.value));
            }}
          >
            <MenuItem value={-1}>Todas</MenuItem>
            {!!parcelas &&
              parcelas.map((parcela) => {
                return (
                  <MenuItem key={parcela.parcela} value={parcela.parcela}>
                    {parcela.parcela}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default FiltroParcela;
