import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableContainer } from "app/shared/components";
import { Theme } from "app/shared/utils";
import { useEffect, useState } from "react";
import ModalDetalhesItem from "../ModalDetalhesItem";
import ChipSituacao from "../ChipSituacao";
import { useDate, useFormat, useRole } from "app/shared/hooks";
import ModalHistorico from "../ModalHistorico";
import { ListaVazia } from "app/shared/components";
import ModalAnalisarItem from "../ModalAnalisarItem";
import { useDispatch, useSelector } from "react-redux";
import { setItemRecursoSelecionado } from "app/redux/reducers/recursoSlice";
import { RecursoItemStatus } from "app/shared/constants";
import { formatMoney } from "app/shared/helpers";
import ModalAnexosItem from "../ModalAnexosItem";

const TableItens = () => {
  const dispatch = useDispatch();
  const [itens, setItens] = useState([]);
  const recursoSelector = useSelector((state) => state.recurso);
  const itemRecursoSelecionado = recursoSelector.itemRecursoSelecionado;
  const { getTextoPeriodo } = useDate();
  const [historicoOpen, setHistoricoOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [analisarOpen, setAnalisarOpen] = useState(false);
  const [anexosOpen, setAnexosOpen] = useState(false);
  const { isRede } = useRole();

  const handleShowItemDetails = (item) => {
    setDetalhesOpen(true);
    dispatch(setItemRecursoSelecionado(item));
  };

  const handleShowAnalisarItem = (item) => {
    setAnalisarOpen(true);
    dispatch(setItemRecursoSelecionado(item));
  };

  const handleShowHistoricoItem = (item) => {
    setHistoricoOpen(true);
    dispatch(setItemRecursoSelecionado(item));
  };
  
  const handleShowAnexosItem = (item) => {
    setAnexosOpen(true);
    dispatch(setItemRecursoSelecionado(item));
  };

  useEffect(() => {
    setItens(recursoSelector.recursoSelecionado?.itens);
  }, [recursoSelector.recursoSelecionado]);

  const getTotal = (item) => {
    const valorTotal =
      (Number(item.custo_capital) + Number(item.custo_custeio)) *
      item.quantidade;
    return formatMoney(valorTotal);
  };

  return (
    <>
      <Typography
        variant="h6"
        color={Theme.colors.blue.dark}
        fontWeight="bold"
        my="10px"
      >
        ITENS
      </Typography>
      <StyledTableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>ITEM</TableCell>
              <TableCell>AÇÃO</TableCell>
              <TableCell align="left">PERÍODO</TableCell>
              <TableCell align="left">CAPITAL (R$)</TableCell>
              <TableCell align="left">CUSTEIO (R$)</TableCell>
              <TableCell align="left">QUANTIDADE</TableCell>
              <TableCell align="left">TOTAL (R$)</TableCell>
              <TableCell align="left">SITUAÇÃO</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((item) => (
              <TableRow key={`row_${item.id}_itens`}>
                <TableCell align="left">{item.id}</TableCell>
                <TableCell component="th" scope="row">
                  {item.acao}
                </TableCell>
                <TableCell align="left">
                  {getTextoPeriodo(item.inicio, item.termino)}
                </TableCell>
                <TableCell align="left">
                  {formatMoney(item.custo_capital)}
                </TableCell>
                <TableCell align="left">
                  {formatMoney(item.custo_custeio)}
                </TableCell>
                <TableCell align="left">{item.quantidade}</TableCell>
                <TableCell align="left">{getTotal(item)}</TableCell>
                <TableCell align="left">
                  <ChipSituacao status={item.situacao.status} />
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  <Button
                    disableElevation
                    style={{ marginBottom: 4 }}
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => {
                      handleShowItemDetails(item);
                    }}
                  >
                    DETALHES
                  </Button>
                  {isRede &&
                    item.situacao.status !== RecursoItemStatus.DEFERIDO && (
                      <Button
                        disableElevation
                        style={{ marginBottom: 4 }}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="info"
                        onClick={() => {
                          handleShowAnalisarItem(item);
                        }}
                      >
                        ANALISAR
                      </Button>
                    )}
                  {item.situacao.status === RecursoItemStatus.DEFERIDO && (
                      <Button
                        disableElevation
                        style={{ marginBottom: 4, backgroundColor: "#402E7A" }}
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => {
                          handleShowAnexosItem(item);
                        }}
                      >
                        ANEXOS ({item.anexos?.length})
                      </Button>
                    )}
                  <Button
                    disableElevation
                    color="secondary"
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => {
                      handleShowHistoricoItem(item);
                    }}
                  >
                    HISTÓRICO
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {itens.length <= 0 && <ListaVazia isListaVazia={itens.length <= 0} />}
      {detalhesOpen && itemRecursoSelecionado && (
        <ModalDetalhesItem
          isOpen={detalhesOpen}
          onClose={() => {
            setDetalhesOpen(false);
          }}
        />
      )}
      {analisarOpen && itemRecursoSelecionado && (
        <ModalAnalisarItem
          isOpen={analisarOpen}
          onClose={() => {
            setAnalisarOpen(false);
          }}
        />
      )}
      {historicoOpen && itemRecursoSelecionado && (
        <ModalHistorico
          open={historicoOpen}
          onClose={() => setHistoricoOpen(false)}
          paramsId={{
            item_id: itemRecursoSelecionado.id,
          }}
        />
      )}
      {anexosOpen && itemRecursoSelecionado && (
        <ModalAnexosItem
          isOpen={anexosOpen}
          onClose={() => setAnexosOpen(false)}
          paramsId={{
            item_id: itemRecursoSelecionado.id,
          }}
        />
      )}
    </>
  );
};

export default TableItens;
