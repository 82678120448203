import { Chip } from "@mui/material";
import { DashboardPage } from "../../pages";
import { RoutesPath } from "app/shared/constants";

export const DashboardRoutes = [
  {
    index: true,
    element: <DashboardPage />,
    handle: {
      crumb: () => <Chip size="small" variant="outlined" label={RoutesPath.DASHBOARD.name} />,
    },
  },
];
