import { TextField } from "@mui/material";

const DatePicker = ({ value, onChangeDate, minDate, maxDate }) => {
  return (
    <TextField
      fullWidth
      size="small"
      type="date"
      value={value}
      onChange={(e) => onChangeDate(e.target.value)}
      inputProps={{
        min: minDate,
        max: maxDate,
      }}
    />
  );
};

export default DatePicker;
