import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { FileViewer, UploadComponent } from "app/shared/components";
import { TipoAnexo } from "app/shared/constants";
import React from "react";
import { NumericFormat } from "react-number-format";
import { v4 as uuidv4 } from "uuid";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
    />
  );
});

const EdicaoCreditoForm = ({
  valorCredito,
  setFiles,
  currentFiles = [],
  setValorCredito,
  loading,
}) => {

  const handleChange = (event) => {
    setValorCredito(event);
  };

  const handleUpload = (files) => {
    const uploadFiles = files.map((file) => ({
      file,
      id: `${uuidv4()}-new`,
      name: file.name,
      preview: URL.createObjectURL(file),
      type: file.type,
      tipo: TipoAnexo.COMPROVANTE_CREDITO,
    }));
    setFiles(uploadFiles);
  };

  const handleRemoveFile = (id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
  };

  return (
    <FormControl fullWidth>
      <Typography variant="button" color="#59829d" mb={1}>
        EDIÇÃO DE CRÉDITO*
      </Typography>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={valorCredito || ""}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputMode: "numeric",
        }}
      />
      <Typography variant="button" color="#59829d" marginTop={2} mb={1}>
        ANEXAR COMPROVANTE*
      </Typography>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <UploadComponent
            multiple={false}
            disabled={loading}
            message="Arraste aqui (JPEG, JPG, PNG e PDF) até 10MB"
            onUpload={(files) => {
              handleUpload(files);
            }}
          />
        </Grid>
        {currentFiles
          .filter((file) => file.tipo === TipoAnexo.COMPROVANTE_CREDITO)
          .map((file) => (
            <Grid item>
              <FileViewer
                key={file.id}
                file={file}
                disabled={loading}
                onDelete={handleRemoveFile}
              />
            </Grid>
          ))}
      </Grid>
    </FormControl>
  );
};

export default EdicaoCreditoForm;
