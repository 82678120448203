import styled from "styled-components";

export const LayoutContainer = styled.div`
    background-color: #F9FAFC;
    width: 100%;
    min-height: 100vh;
    padding: 52px 0px 80px;
    overflow: visible;
`

export const Content = styled.div`
    margin-top: 25px;
    padding: 10px 50px;
`
