import { AccordionSummary } from "@mui/material";
import { Theme } from "app/shared/utils";
import styled from "styled-components";

export const StyledAccordionSummary  = styled(AccordionSummary)`
    background-color: ${Theme.colors.blue.default}!important;
    color: #fff !important;
    height: 45px !important;
    min-height: 0 !important;
    border-radius: 5px !important;
`