const DASHBOARD = {
  name: "Dashboard",
  path: "/dashboard",
  parents: [],
};
const RECURSO = {
  name: "Recurso",
  path: "/recurso",
  parents: [],
};

const RECURSO_DETAILS = {
  name: "Detalhes",
  path: "/recurso/detalhes",
  parents: [RECURSO],
};

const RECURSO_CADASTRO = {
  name: "Cadastro",
  path: "/recurso/form",
  parents: [RECURSO],
};

const RECURSO_BLOQUEIO = {
  name: "Liberação/Bloqueio de parcela plano",
  path: "/recurso/block",
  parents: [],
};

export const RoutesArray = [
  DASHBOARD,
  RECURSO,
  RECURSO_DETAILS,
  RECURSO_CADASTRO,
  RECURSO_BLOQUEIO,
];

export default {
  DASHBOARD,
  RECURSO,
  RECURSO_DETAILS,
  RECURSO_CADASTRO,
  RECURSO_BLOQUEIO,
};
