import { Grid, Paper, Typography } from "@mui/material";
import { ClickableGrid, IconContainer } from "./styles";
import { Theme } from "app/shared/utils";

const CardInfo = ({
  icon,
  hasBorder = false,
  hasElevation = true,
  borderColor = "#2a2a2ad1",
  onClick = null,
  isSelected = false,
  label,
  children,
  containerStyles = {},
  isHover = false
}) => {
  return (
    <ClickableGrid
      item
      display="flex"
      flexDirection="column"
      component={hasElevation ? Paper : null}
      alignItems="center"
      isSelected={isSelected}
      {...(hasBorder && {
        border: `1px solid ${borderColor}`,
        borderRadius: 1,
      })}
      onClick={onClick ?? undefined}
      {...containerStyles}
      isHover={isHover}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {children}
      {label && (
        <Typography
          id="cardinfo-label"
          variant="h6"
          color={Theme.colors.black}
          fontWeight="bold"
        >
          {label}
        </Typography>
      )}
    </ClickableGrid>
  );
};

export default CardInfo;
