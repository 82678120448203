import { Button, Grid, Paper, Typography } from "@mui/material";
import { HeaderAnoGlobal } from "app/shared/components";
import { Theme } from "app/shared/utils";
import { FiltrosBloqueio, TabelaPlanos } from "./components";

const RecursoBloqueioPage = () => {
  return (
    <>
      <HeaderAnoGlobal
        titulo="LIBERAÇÃO/BLOQUEIO DE PARCELA PLANO"
        colorTitulo={Theme.colors.blue.dark}
        showAnoSelect={false}
      />
      <FiltrosBloqueio />
      <TabelaPlanos />
    </>
  );
};

export default RecursoBloqueioPage;
