const useFormat = () => {
  const formataValorParaReais = (valor) => {
    if (!valor) return valor;
    
    return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
  }

  const aplicarMaskCNPJ = (cnpj) => {
    if (!cnpj) return cnpj;
    
    return cnpj
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  }

  return {
    formataValorParaReais,
    aplicarMaskCNPJ
  }
};

export default useFormat;
