import { Grid, Hidden } from "@mui/material";
import { Container, BannerImage } from "./styles";
import LoginForm from "./LoginForm";
import { LayoutFooter } from "app/shared/components";

const LoginPage = () => {
  return (
    <Container>
      <Grid height="100%" container width="100%" alignContent="center">
        <Grid
          item
          container
          md={6}
          sm={12}
          direction="column"
          display="flex"
          alignItems="center"
        >
          <LoginForm />
        </Grid>
        <Hidden mdDown>
          <Grid item md={6} sm={0}>
            <BannerImage src="img/banner-login.png" />
          </Grid>
        </Hidden>
      </Grid>
      <LayoutFooter />
    </Container>
  );
};

export default LoginPage;
