import { Alert, Box } from "@mui/material";

const ListaVazia = ({ isListaVazia = false, message = "Lista vazia" }) => {
  if (!isListaVazia) return null;

  return (
    <Box width='100%'>
      <Alert severity="info">{message}</Alert>
    </Box>
  );
};

export default ListaVazia;
