import { Grid } from "@mui/material";
import styled, { css } from "styled-components";

const selectedStyled = css`
  background-color: #c9e6ff !important;
  border: none !important;

  #cardinfo-label {
    color: #176de699;
  }
`;

export const ClickableGrid = styled(Grid)`
  padding: 10px;
  position: relative;
  justify-content: center;
  background-color: white;
  ${(props) => !!props.onClick && "cursor: pointer;"}
  ${(props) => props.isSelected && selectedStyled}
  ${(props) => props.isHover && `
    &:hover { 
      opacity: 0.6;
      transition: 0.5;
    }
  `}

  
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`