import { getUser } from 'app/services/auth/service'
import { redirect } from 'react-router-dom'

const publicMiddleware = async ({ request }) => {
    const url = request.url
    const path = url.split("/").splice(3).join("/")
    const isRoot = path === ""
    const user = await getUser()
    const isUserLogged = !!user?.id

    if (isUserLogged) {
        return redirect("/dashboard")
    }

    if (isRoot) {
        return redirect("/auth")
    }


    return null
}

export default publicMiddleware