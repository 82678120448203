import { ListaVazia, StyledTableContainer } from "app/shared/components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import ChipSituacao from "pages/recurso/components/ChipSituacao";

const TableSituacao = ({ planos = [], isLoading = false }) => {
  if (planos.length === 0 && !isLoading) {
    return <ListaVazia isListaVazia={true} />;
  }
  return (
    <Grid container>
      <StyledTableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>PLANO</TableCell>
              <TableCell align="left">GRE</TableCell>
              <TableCell align="left">ESCOLA</TableCell>
              <TableCell align="left">PARCELA</TableCell>
              <TableCell align="left">SITUAÇÃO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planos.map((plano, index) => {
              return (
                <TableRow>
                  <TableCell align="left">
                    {plano.tipo_plano.descricao}
                  </TableCell>
                  <TableCell align="left">
                    {plano.superintendencia.nome}
                  </TableCell>
                  <TableCell align="left">{plano.escola.nome}</TableCell>
                  <TableCell align="left">{plano.parcela.parcela}</TableCell>
                  <TableCell align="left">
                    <ChipSituacao status={plano.status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Grid>
  );
};

export default TableSituacao;
