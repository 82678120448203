export const formatMoney = (value, maximumFractionDigits = 2) => {
  const formCurrency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits,
  });
  return formCurrency.format(value);
};

export const formatParams = (filtros) => {
  let filtrosClone = { ...filtros };
  for (let prop in filtrosClone) {
    if (filtrosClone[prop] === -1) delete filtrosClone[prop];
  }
  return filtrosClone;
};

export const formatCNPJ = (value) => {
  const cpnpjWithoutSpaces = value.replace(/ /g, "");
  return (
    cpnpjWithoutSpaces?.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    ) ?? ""
  );
};

export const formatTypedCNPJ = (value) => {
  if (!value) return "";
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const formatParamFilter = (value) => {
  return value === -1 ? undefined : value;
};

export const getFiltrosSelecionados = (filtros) => {
  let filtrosClone = { ...filtros };
  for (let prop in filtrosClone) {
    if (filtrosClone[prop] === -1 || filtrosClone[prop] === undefined)
      delete filtrosClone[prop];
  }
  return filtrosClone;
};
