import { useEffect } from "react";
import LoaderComponent from "../LoaderComponent";
import { useApp, useService } from "app/shared/hooks";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAnosFilter } from "app/redux/reducers/globalSlice";
import { buscaAnos } from "app/services/aplicacao";
import { setAnoFiltro } from "app/redux/reducers/recursoSlice";

const FiltroAno = () => {
  const dispatch = useDispatch();
  const { user } = useApp();
  const anos = useSelector((state) => state.global.anosFilter);
  const ano = useSelector((state) => state.recurso.anoFiltro);

  const {
    request: getAnosReq,
    response,
    retry: retryBuscaAnos,
  } = useService(buscaAnos, true, (data) => {
    dispatch(setAnosFilter(data));
  }, () => { }, { cache: true, cacheKey: "getAnosAll" });

  useEffect(() => {
    if (user && ano === -1) {
      dispatch(setAnoFiltro(user.ano_base));
    }
  }, [user]);

  useEffect(() => {
    if (!anos || anos?.length === 0) {
      getAnosReq();
    }
  }, []);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retryBuscaAnos}
        message="Buscando anos"
        errorMessage="Falha ao carregar anos"
      />
      {!response.loading && (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ano</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={ano}
            size="small"
            label="Ano"
            onChange={(e) => dispatch(setAnoFiltro(e.target.value))}
          >
            {anos?.map((a) => {
              return (
                <MenuItem key={a.ano} value={a.ano}>
                  {a.ano}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default FiltroAno;
