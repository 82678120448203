import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { salvaParcelas } from "app/services/parcela";
import { GenericModal } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { useSelector } from "react-redux";
import toastr from "toastr";

const getComplementoParcelas = (plano) => {
  const parcelas = plano.parcelas || [];
  return new Array(plano.num_parcelas).fill(null).map((_, index) => {
    const currentParcelaNumber = index + 1;
    const currentParcela = parcelas.find(
      (parcela) => parcela.parcela === currentParcelaNumber
    );
    if (currentParcela) {
      return currentParcela;
    }
    return {
      id: "temp" + currentParcelaNumber,
      parcela: currentParcelaNumber,
      status: 0,
    };
  });
};

const ModalLiberaBloqueia = ({ plano, onClose, onSuccess }) => {
  const [parcelas, setParcelas] = useState(getComplementoParcelas(plano));
  const ano = useSelector((state) => state.recurso.anoFiltro);

  const handleSuccessSave = () => {
    toastr.success("Parcelas editadas com sucesso!");
    onSuccess();
    onClose();
  };

  const {
    request,
    response: { loading },
  } = useService(salvaParcelas, false, handleSuccessSave);

  const hasAtLeastOnSelected = parcelas.some((parcela) => parcela.status === 1);

  const handleChangeParcela = (parcelaId, check) => {
    setParcelas((prev) => {
      return prev.map((parcela) => {
        if (parcela.id === parcelaId) {
          return {
            ...parcela,
            status: check ? 1 : 0,
          };
        }
        return parcela;
      });
    });
  };

  const handleSave = () => {
    let formatedParcelas = {};
    parcelas.forEach((parcela, index) => {
      const idTemp = String(parcela.id).includes("temp")
      const isIgnoredTemp = idTemp && parcela.status === 0;
      if (!isIgnoredTemp) {
        if (!idTemp) {
          formatedParcelas[`parcelas[${index}][id]`] = parcela.id;
        }
        formatedParcelas[`parcelas[${index}][parcela]`] = parcela.parcela;
        formatedParcelas[`parcelas[${index}][status]`] = parcela.status;
      }
    });

    const payload = {
      ano: ano,
      tipo_plano_id: plano.id,
      ...formatedParcelas,
    };
    request(payload);
  };

  return (
    <GenericModal
      hasCloseIcon={false}
      preventCloseClickOutside={loading}
      open={!!plano}
      onClose={onClose}
      size="xs"
    >
      <Typography variant="h6" color={Theme.colors.blue.dark}>
        LIBERA/BLOQUEIA PARCELA PLANO
      </Typography>

      <Box mt="20px">
        <Typography
          fontWeight="bold"
          variant="body1"
          color={Theme.colors.gray.ligth}
        >
          PLANO
        </Typography>
        <Typography variant="body1">{plano.descricao}</Typography>
        <Typography
          mt="10px"
          fontWeight="bold"
          variant="body1"
          color={Theme.colors.gray.ligth}
        >
          ANO
        </Typography>
        <Typography variant="body1">{ano}</Typography>
        <Typography
          mt="10px"
          fontWeight="bold"
          variant="body1"
          color={Theme.colors.gray.ligth}
        >
          PARCELA
        </Typography>
        <Alert
          icon={<FiInfo />}
          color={hasAtLeastOnSelected ? "info" : "error"}
        >
          Pelo menos uma parcela deve ser selecionada
        </Alert>
        <Grid container>
          {parcelas.map((parcela) => {
            return (
              <Grid item md={4}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={parcela.status === 1}
                  disabled={loading}
                  onChange={(e) => {
                    handleChangeParcela(parcela.id, e.target.checked);
                  }}
                  label={`Parcela ${parcela.parcela}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" gap="10px">
        <Button
          disabled={loading}
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          CANCELAR
        </Button>
        <Button
          disabled={!hasAtLeastOnSelected || loading}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {loading ? "Salvando..." : "SALVAR"}
        </Button>
      </Box>
    </GenericModal>
  );
};

export default ModalLiberaBloqueia;
