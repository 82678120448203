import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { buscaValoresPorParcelaPlanos } from "app/services/plano";
import { BarChart, CardInfo, LoaderComponent } from "app/shared/components";
import { formatMoney, formatParams } from "app/shared/helpers";
import { useApp, useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const PlanosGraficoSection = () => {
  const { ano } = useApp();

  const { tiposPlano } = useSelector((state) => state.dashboard);
  const filtros = useSelector((state) => state.global.filtros);
  const hasTiposFiltro = tiposPlano?.length > 0;
  const [selectedTipo, setSelectedTipo] = useState(null);

  const { request, response, retry } = useService(buscaValoresPorParcelaPlanos, false, () => { }, () => { }, { cache: true, cacheKey: "getValoresPorParcela" });

  useEffect(() => {
    if (tiposPlano[0]?.id) {
      setSelectedTipo(tiposPlano[0]?.id);
      return;
    }

    if (tiposPlano.length > 0) {
      setSelectedTipo(-1);
    }
  }, [tiposPlano]);

  const getDados = () => {
    if (!selectedTipo) return;

    const params = {
      "parcela[ano]": ano,
      with: "parcela",
      escola_id: filtros.escola,
      si_id: filtros.gre,
      tipo_plano_id: selectedTipo,
    };
    request(formatParams(params));
  };

  useEffect(() => {
    if (hasTiposFiltro) getDados();
  }, [selectedTipo, filtros, ano, hasTiposFiltro]);

  const dadosGrafico = response?.data?.data;

  const formatedDadosGrafico = useMemo(() => {
    const entradas = [];
    const saidas = [];
    const parcelas = [];
    let totalEntradas = 0;
    let totalSaidas = 0;
    if (!dadosGrafico) {
      return {
        entradas,
        saidas,
        parcelas,
        totalEntradas,
        totalSaidas,
      };
    }
    dadosGrafico.sort((a, b) => a.parcela.parcela - b.parcela.parcela);
    dadosGrafico.forEach((dado) => {
      entradas.push(dado.valor_entrada);
      saidas.push(dado.valor_saida);
      parcelas.push(dado.parcela.parcela);
      totalEntradas += dado.valor_entrada;
      totalSaidas += dado.valor_saida;
    });
    return {
      entradas,
      saidas,
      parcelas,
      totalEntradas,
      totalSaidas,
    };
  }, [dadosGrafico]);

  return (
    <>
      <Grid
        container
        direction="column"
        component={Paper}
        padding="20px 20px 5px"
        mb="20px"
      >
        <Grid item>
          <Typography
            variant="h6"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            Recursos plano
          </Typography>
        </Grid>
        <Grid item mt="10px">
          <Select
            id="recurso-select"
            labelId="recurso-label"
            value={selectedTipo}
            onChange={(event) => setSelectedTipo(event.target.value)}
            size="small"
            disabled={!hasTiposFiltro}
            style={{
              width: "100%",
              maxWidth: 200,
            }}
          >
            <MenuItem value={-1}>Selecione Tipo</MenuItem>
            {tiposPlano.map((tipo) => {
              return (
                <MenuItem key={tipo.id} value={tipo.id}>
                  {tipo.descricao}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid container spacing={2} mt="10px">
          <Grid item direction="column" md={4} sm={12} xs={12}>
            <LoaderComponent
              {...response}
              loading={response.loading || !hasTiposFiltro}
              retry={retry}
              message="Carregando dados do gráfico"
            >
              <CardInfo
                hasBorder
                hasElevation={false}
                label="Total de entrada de recursos"
                borderColor="#0000004D"
                containerStyles={{
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={Theme.colors.grenn}
                >
                  {formatMoney(formatedDadosGrafico.totalEntradas)}
                </Typography>
              </CardInfo>
              <CardInfo
                hasBorder
                hasElevation={false}
                label="Total de saída de recursos"
                borderColor="#0000004D"
                containerStyles={{
                  marginTop: "10px",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={Theme.colors.red}
                >
                  {formatMoney(formatedDadosGrafico.totalSaidas)}
                </Typography>
              </CardInfo>
            </LoaderComponent>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <BarChart
              isLoading={response.loading || !hasTiposFiltro}
              loadingMessage="Gerando gráfico de recursos..."
              entradas={formatedDadosGrafico.entradas}
              saidas={formatedDadosGrafico.saidas}
              parcelas={formatedDadosGrafico.parcelas}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlanosGraficoSection;
