import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { setPaginaAtualListagemRecursosBloqueio } from "app/redux/reducers/recursoSlice";
import { buscaTipoPlanos } from "app/services/plano";
import {
  ListaVazia,
  LoaderComponent,
  Paginacao,
  StyledTableContainer,
} from "app/shared/components";
import { getFiltrosSelecionados } from "app/shared/helpers";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalLiberaBloqueia from "../ModalLiberaBloqueia";

const PER_PAGE = 5;
const TabelaPlanos = () => {
  const dispatch = useDispatch();
  const filtros = useSelector((state) => state.global.filtros);
  const ano = useSelector((state) => state.recurso.anoFiltro);

  const [selectedPlano, setSelectedPlano] = useState(null);
  const [total, setTotal] = useState(0);

  const page = useSelector(
    (state) => state.recurso.paginaAtualListagemRecursosBoqueio
  );

  const setPage = (newPage) => {
    dispatch(setPaginaAtualListagemRecursosBloqueio(newPage));
  };

  const handleSuccess = (data) => {
    setTotal(data.total);
  };

  const {
    request: buscaPlanos,
    response,
    retry,
  } = useService(buscaTipoPlanos, false, handleSuccess, () => { }, { cache: true, cacheKey: "getPlanosAll" });

  const getData = (ignoreCache = false) => {

    if (ano === -1) return;
    const params = {
      with: ["parcelas", "ano"],
      page: page,
      limit: PER_PAGE,
      id: filtros.plano,
      parcelas_ano: ano,
      parcelas_valor: filtros.parcela,
    };
    const filteredParams = getFiltrosSelecionados(params);

    buscaPlanos(filteredParams, ignoreCache);
  };

  useEffect(() => {
    getData();
  }, [filtros.plano, filtros.parcela, ano, page]);

  const lastPage = response?.data?.last_page || 0;
  const planosLista = response?.data?.data || [];

  return (
    <Box mt="20px" mb="30px" component={Paper}>
      <Box
        p="10px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" color={Theme.colors.blue.dark}>
          PARCELAS LIBERADAS
        </Typography>
        <Typography variant="h6" color={Theme.colors.blue.dark}>
          {response.loading ? "..." : total} tipos de planos
        </Typography>
      </Box>
      <Grid>
        <LoaderComponent
          {...response}
          retry={retry}
          message="Buscando parcelas"
          errorMessage="Falha ao carregar parcelas"
        />
        {planosLista.length > 0 && (
          <StyledTableContainer style={{ borderRadius: 0 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>PLANO</TableCell>
                  <TableCell align="left">PARCELA</TableCell>
                  <TableCell align="left">ANO</TableCell>
                  <TableCell align="center">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planosLista.map((plano) => {
                  return (
                    <TableRow>
                      <TableCell align="left">{plano.descricao}</TableCell>
                      <TableCell align="left" style={{ maxWidth: 300 }}>
                        <Grid container spacing={1}>
                          {plano.parcelas
                            .sort((a, b) => a.parcela - b.parcela)
                            .map((parcela) => {
                              const color =
                                parcela.status === 1 ? "success" : "default";
                              return (
                                <Grid item>
                                  <Chip
                                    color={color}
                                    size="small"
                                    label={`Parcela ${parcela.parcela}`}
                                  ></Chip>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">{ano}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedPlano(plano);
                          }}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        <ListaVazia
          isListaVazia={planosLista.length === 0 && !response.loading}
        />
        <Grid width="100%">
          {lastPage > 0 && (
            <Paginacao
              setNumeroPagina={(pagina) => {
                setPage(pagina);
              }}
              pagina={page}
              totalItems={lastPage}
              style={{ float: "right", padding: 10 }}
            />
          )}
        </Grid>
      </Grid>

      {selectedPlano && (
        <ModalLiberaBloqueia
          plano={selectedPlano}
          onClose={() => setSelectedPlano(null)}
          onSuccess={() => {
            getData(true)
          }}
        />
      )}
    </Box>
  );
};

export default TabelaPlanos;
