import { Theme } from "app/shared/utils";
import styled from "styled-components";

export const Preview = styled.div`
  width: ${(props) => `${props.size + 20}px`};
  height: ${(props) => `${props.size}px`};
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${Theme.colors.gray.ligth};
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

export const ImageView = styled.img`
  max-height: 500px;
`;
