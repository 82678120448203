import { TipoAnexo } from "app/shared/constants";
import { convertToFormData, getImageBlob } from "app/shared/helpers";
import toastr from "toastr";

export const isValidForm = (form) => {
  const errors = [];
  if (!form.meta.trim()) errors.push("Necessário informar meta");
  if (!form.objetivo_estrategico.trim())
    errors.push("Necessário informar Objetivo estratégico");
  if (!form.justificativa.trim())
    errors.push("Necessário informar Justificativa");
  errors.forEach((erro) => toastr.warning(erro));
  return errors.length === 0;
};

const formataItensRequest = (itens) => {
  let itensFormated = {};

  itens.forEach((item, index) => {
    if (!String(item.id).includes("new")) {
      itensFormated[`itens[${index}][id]`] = item.id;
    }
    if (item.inicio && item.termino) {
      itensFormated[`itens[${index}][inicio]`] = item.inicio;
      itensFormated[`itens[${index}][termino]`] = item.termino;
    }

    itensFormated[`itens[${index}][acao]`] = item.acao;
    itensFormated[`itens[${index}][responsavel]`] = item.responsavel;
    itensFormated[`itens[${index}][financiador]`] = item.financiador;
    itensFormated[`itens[${index}][cnpj_fornecedor]`] =
      item.cnpj_fornecedor?.replace(/[^\d]+/g, "") || undefined;
    itensFormated[`itens[${index}][quantidade]`] = item.quantidade;
    itensFormated[`itens[${index}][descricao]`] = item.descricao;
    if (item.custo_capital?.length > 0) {
      itensFormated[`itens[${index}][custo_capital]`] = item.custo_capital;
    }
    if (item?.custo_custeio?.length > 0) {
      itensFormated[`itens[${index}][custo_custeio]`] = item.custo_custeio;
    }
  });

  return itensFormated;
};

export const formataAnexosRequest = (anexos) => {
  let anexosFormated = {};

  anexos.forEach((anexo, index) => {
    if (!String(anexo.id).includes("new")) {
      anexosFormated[`info_anexos[${index}][id]`] = anexo.id;
    }
    anexosFormated[`info_anexos[${index}][tipo]`] = anexo.tipo;
    anexosFormated[`anexos[${index}]`] = anexo.file;
  });

  return anexosFormated;
};

export const formataRequestRecurso = (form) => {
  const hasId = !!form?.id;
  const formatedData = {
    ...(hasId ? { id: form?.id } : {}),
    parcela_id: form.parcelaId,
    objetivo_estrategico: form.objetivo_estrategico,
    meta: form.meta,
    justificativa: form.justificativa,
    escola_id: form.escola_id,
    ...formataItensRequest(form.itens),
    ...formataAnexosRequest(form.anexos),
    ...(hasId ? { _method: "put" } : {}),
  };

  return convertToFormData(formatedData);
};

export const formatResponseAnexos = (anexos) => {
  const pormises = anexos
    .filter((anexo) => anexo.tipo !== TipoAnexo.COMPROVANTE_CREDITO)
    .map(async (anexo) => {
      return {
        file: await getImageBlob(anexo.url, anexo.nome),
        id: anexo.id,
        name: anexo.nome,
        preview: anexo.url,
        type: anexo.url.slice(-3),
        tipo: anexo.tipo,
      };
    });
  return Promise.all(pormises);
};

const formatItens = (itens) => {
  return itens.map((item) => {
    return {
      ...item,
      descricao: item.descricao || "",
    };
  });
};

export const formateResponseRecurso = async (data) => {
  if (!data) return null;
  const formatedAnexos = await formatResponseAnexos(data.anexos);
  const formatedItens = formatItens(data.itens);
  return { ...data, anexos: formatedAnexos, itens: formatedItens };
};

export const validarCNPJ = (cnpj) => {
  if (!cnpj) return false;
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};
