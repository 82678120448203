import { useState } from "react";
import { Preview, ImageView } from "./styles";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import { FiX, FiTrash, FiDownload } from "react-icons/fi";
import { Theme } from "app/shared/utils";

const ImagePreview = ({
  size = 45,
  disabled = false,
  imageName,
  url = "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
  onDelete,
}) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  const handleDownload = () => {
    saveAs(url, imageName);
  };

  if (!url) return null;
  return (
    <Grid item justifyContent="center">
      <Preview size={size} src={url} onClick={toggleModal} />

      {imageName && (
        <Tooltip title={imageName} arrow>
          <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            variant="body2"
            maxWidth={size + 20}
            mt="5px"
          >
            {imageName}
          </Typography>
        </Tooltip>
      )}
      {(!!onDelete && !disabled) && (
        <Grid container justifyContent="center">
          <IconButton onClick={onDelete}>
            <FiTrash size={18} />
          </IconButton>
        </Grid>
      )}
      <Dialog onClose={toggleModal} open={open}>
        <Box
          padding="10px"
          display="flex"
          flexDirection="row"
          justifyContent={imageName ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {imageName && (
            <Box display="flex" alignItems="center">
              <Typography mr="10px" variant="body2">
                {imageName}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDownload}
                endIcon={
                  <FiDownload color={Theme.colors.blue.dark} size={15} />
                }
              >
                Download
              </Button>
            </Box>
          )}
          <IconButton onClick={toggleModal}>
            <FiX color={Theme.colors.black} size={15} />
          </IconButton>
        </Box>
        <ImageView src={url} />
      </Dialog>
    </Grid>
  );
};

export default ImagePreview;
