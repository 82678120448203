import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F9FAFC;
`

export const LogoImage = styled.img`
    width: 300px;
`

export const BannerImage = styled.img`
    max-height: 500px;
`

export const ErrorLabel = styled.span`
    font-size: 14px;
    color: #ca5343;
    margin: 5px 0px;
`