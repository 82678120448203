import { useCallback } from 'react';

const useFile = () => {
  const getExtension = useCallback((fileName) => {
    return fileName.split('.').pop();
  }, []);

  const convertBytesToMB = useCallback((bytes) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }, []);

  const getImageBlob = async (url) => {
    const response = await fetch(url)
    const blob = await response.blob()
    const file = new File([blob], 'image.jpg', { type: blob.type })
    return file
  }

  return { getExtension, convertBytesToMB, getImageBlob };
};

export default useFile;