import { Breadcrumbs, Chip } from "@mui/material";
import { RoutesArray } from "app/shared/constants";
import { FiChevronRight } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = () => {
  const location = useLocation();

  const breadCrumbs = (() => {
    const crumbs = [];
    const currentRoute = RoutesArray.find(
      (route) => route.path.slice(1).replace(/\/+$/, '') === location.pathname.slice(1).replace(/\/+$/, '')
    );
    currentRoute?.parents?.forEach((parent) => {
      crumbs.push(
        <Link underline="hover" color="inherit" to={parent.path}>
          <Chip size="small" label={parent.name} />
        </Link>
      );
    });
    crumbs.push(
      <Chip size="small" variant="outlined" label={currentRoute.name} />
    );
    return crumbs;
  })();

  return (
    <Breadcrumbs separator={<FiChevronRight />}>
      {breadCrumbs.map((crumb, index) => (
        <div key={index}>{crumb}</div>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
