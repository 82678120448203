import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { setItemRecursoSelecionado } from "app/redux/reducers/recursoSlice";
import { buscaHistoricoItemRecurso } from "app/services/recurso";
import { ListaVazia, LoaderComponent } from "app/shared/components";
import { TimeLine } from "app/shared/components";
import { RecursoItemStatus } from "app/shared/constants";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "fixed",
  top: "0",
  bottom: "0",
  right: "0",
  zIndex: 9999,
  width: "100%",
  maxWidth: "400px",
  height: "100vh",
  bgcolor: "background.paper",
  display: "flex",
  flexDirection: "column",
};

const recursoItemStatusLabel = {
  0: "Criado",
  1: "Deferido",
  2: "Indeferido",
  3: "Reenviado",
};

const ModalHistorico = ({ open, onClose, paramsId }) => {
  const dispatch = useDispatch();
  const [historicoItem, setHistoricoItem] = useState();
  const filtros = useSelector((state) => state.global);
  const recursoSelector = useSelector((state) => state.recurso);
  const recursoSelecionado = recursoSelector.recursoSelecionado;
  const itemRecursoSelecionado = recursoSelector.itemRecursoSelecionado;

  useEffect(() => {
    const teste = document.getElementsByClassName("navbar-mobi-nav");
    if (teste.length > 0) {
      teste[0].style["z-index"] = 99;
    }

    return () => {
      if (teste.length > 0) {
        teste[0].style["z-index"] = 9999;
      }
    };
  }, []);

  const handleLoadHistoricoItem = (data) => {
    if (data) {
      const items = data.data?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setHistoricoItem(items);
    }
  };

  const {
    request: getHistoricoItemReq,
    response,
    retry: retryHistoricoItem,
  } = useService(buscaHistoricoItemRecurso, false, handleLoadHistoricoItem);

  useEffect(() => {
    getHistoricoItemReq({
      ...paramsId,
      with: "item,usuario",
    });

    return () => {
      dispatch(setItemRecursoSelecionado(null));
    };
  }, []);

  const montaTextoParaHistorico = (item) => {
    const atualizadoEm = new Date(item.updated_at);
    const atualizadoEmFormatado = atualizadoEm.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    return `Item ${item.item.id} - ${recursoItemStatusLabel[item.status]} por ${
      item.usuario.nome
    } (${atualizadoEmFormatado})`;
  };

  const coresSituacao = [
    {
      status: RecursoItemStatus.DEFERIDO,
      color: "#559659",
    },
    {
      status: RecursoItemStatus.REENVIADO,
      color: "#1e88e5",
    },
    {
      status: RecursoItemStatus.INDEFERIDO,
      color: "#de6363",
    },
    {
      status: RecursoItemStatus.CRIADO,
      color: "#000000",
    },
  ];

  const items = (() => {
    return (
      historicoItem &&
      historicoItem.map((item) => {
        const corStatus = coresSituacao.find(
          (cor) => cor.status === item.status
        );

        return (
          <Grid
            borderRadius={2}
            p={2}
            direction="column"
            bgcolor="#fff"
            borderRight={`5px solid ${corStatus.color}`}
          >
            <Typography color={Theme.colors.black} variant="body1">
              {montaTextoParaHistorico(item)}
            </Typography>
            <Typography color={Theme.colors.gray.dark} variant="caption">
              {item.descricao}
            </Typography>
          </Grid>
        );
      })
    );
  })();

  const montaDataCriadoEm = () => {
    const criadoEm = new Date(recursoSelecionado.created_at);
    const criadoEmData = criadoEm.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const horas = criadoEm.getHours().toString().padStart(2, "0");
    const minutos = criadoEm.getMinutes().toString().padStart(2, "0");

    return `${criadoEmData} às ${horas}:${minutos}`;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Grid display="flex" container p={2} direction="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
            marginBottom="10px"
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color={Theme.colors.blue.dark}
            >
              Histórico do plano {recursoSelecionado.tipo_plano.descricao}
            </Typography>
            <IconButton onClick={onClose}>
              <FiX color={Theme.colors.black} size={15} />
            </IconButton>
          </Box>

          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            CRIADO EM: {montaDataCriadoEm()}
          </Typography>
          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            GRE: {recursoSelecionado.escola.superintendencia.nome}
          </Typography>
          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            ESCOLA: {recursoSelecionado.escola.nome}
          </Typography>
          {itemRecursoSelecionado && (
            <Grid container direction="row" gap={1} my="10px">
              <Chip
                size="small"
                label={`PLANO: ${recursoSelecionado.tipo_plano.descricao}`}
              />
              <Chip
                size="small"
                label={`PARCELA ${recursoSelecionado.parcela.parcela}`}
              />
              <Chip size="small" label={`${filtros.ano}`} />
              <Chip size="small" label={`ITEM: ${itemRecursoSelecionado.id}`} />
            </Grid>
          )}
        </Grid>
        <Divider style={{ padding: "0px 0px" }} />
        <Grid p="10px 10px" width="100%" overflow="auto" height="100%">
          <LoaderComponent
            {...response}
            retry={retryHistoricoItem}
            message="Buscando histórico"
            errorMessage="Falha ao carregar histórico"
          />
          {historicoItem && items?.length > 0 && <TimeLine items={items} />}
          {items?.length === 0 && !response.loading && (
            <ListaVazia isListaVazia message="Nenhum registro encontrado" />
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalHistorico;
