import { Pagination } from "@mui/material";

const Paginacao = ({
  pagina,
  totalItems,
  setNumeroPagina,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setNumeroPagina(value);
  };

  return (
    <Pagination 
      count={totalItems} 
      color="primary" 
      shape="rounded"
      page={pagina}
      onChange={handleChange}
      style={{
        padding: 10
      }}
      {...rest}
    />
  );
};

export default Paginacao;
