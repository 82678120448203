import { Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, GenericModal } from "app/shared/components";
import { Theme } from "app/shared/utils";
import CusteioSection from "./CusteioSection";
import { useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";

const ModalItemForm = ({ open, onClose, onSave, item }) => {
  const isEdit = !!item;
  const initialValue = item || {
    acao: "",
    inicio: null,
    termino: null,
    descricao: "",
    responsavel: "",
    financiador: "",
    custo_capital: "",
    custo_custeio: "",
    quantidade: 1,
    cnpj_fornecedor: "",
  };

  const [errorCusteio, setErrorCusteio] = useState();
  const [newItem, updateNewItem] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    initialValue
  );

  function isDateValid(dateString) {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
          return false;
      }

      const date = new Date(dateString);
      date.setUTCHours(3)
      const year = date.getFullYear();
      const month = date.getMonth() + 1; 
      const day = date.getDate();
  
      const normalizedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      return dateString === normalizedDate;
  }

  const isValidForm = () => {
    const errors = [];
    if (!newItem.acao.trim()) errors.push("Necessário informar ação");
    if (!newItem.responsavel.trim())
      errors.push("Necessário informar responsável");
    if (!newItem.financiador.trim())
      errors.push("Necessário informar financiador");

    if (newItem.inicio || newItem.termino) {
      if (!isDateValid(newItem.inicio) || !isDateValid(newItem.termino))
        errors.push("Selecione uma data válida");

      const inicioDate = new Date(newItem.inicio).setUTCHours(3);
      const terminoDate = new Date(newItem.termino).setUTCHours(3);

      const isValidPeriod = inicioDate <= terminoDate;

      if (!isValidPeriod)
        errors.push("A Data inicial deve ser menor que a de término");
    }
    if (newItem.quantidade <= 0) errors.push("Necessário informar quantidade");
    const hasCusto = !!newItem.custo_capital || !!newItem.custo_custeio;
    if (!hasCusto) errors.push("Necessário informar ao menos um custo");
    if (!hasCusto) errors.push("Necessário informar ao menos um custo");
    if (errorCusteio) errors.push(errorCusteio);

    errors.forEach((erro) => toastr.warning(erro));

    return errors.length === 0;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!isValidForm()) return;
    const id = item?.id || `${uuidv4()}-new`;
    onSave?.({ id, ...newItem });
  };

  return (
    <GenericModal
      title={isEdit ? "EDITAR ITEM" : "NOVO ITEM"}
      open={open}
      onClose={onClose}
      size="sm"
      preventCloseClickOutside
    >
      <Grid container direction="column" my="10px">
        <Typography
          variant="body2"
          fontWeight="bold"
          color={Theme.colors.blue.dark}
        >
          AÇÃO*
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={newItem.acao}
          onChange={(e) => updateNewItem({ acao: e.target.value })}
        />
      </Grid>
      <Grid container mb="10px" spacing={1}>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            variant="body2"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            INÍCIO
          </Typography>
          <DatePicker
            value={newItem.inicio}
            maxDate={newItem.termino}
            onChangeDate={(newDate) => updateNewItem({ inicio: newDate })}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            variant="body2"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            TÉRMINO
          </Typography>
          <DatePicker
            value={newItem.termino}
            minDate={newItem.inicio}
            onChangeDate={(newDate) => updateNewItem({ termino: newDate })}
          />
        </Grid>
      </Grid>
      <Grid container mb="10px" spacing={1}>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            variant="body2"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            RESPONSÁVEL*
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={newItem.responsavel}
            onChange={(e) => updateNewItem({ responsavel: e.target.value })}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            variant="body2"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            FINANCIADO POR*
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={newItem.financiador}
            onChange={(e) => updateNewItem({ financiador: e.target.value })}
          />
        </Grid>
      </Grid>

      <CusteioSection
        value={newItem}
        onChange={(newCusteio) => updateNewItem(newCusteio)}
        setError={setErrorCusteio}
      />
      <Grid container direction="column" mb="10px">
        <Typography
          variant="body2"
          fontWeight="bold"
          color={Theme.colors.blue.dark}
        >
          DESCRIÇÃO ADICIONAL
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={2}
          value={newItem.descricao}
          onChange={(e) => updateNewItem({ descricao: e.target.value })}
        />
      </Grid>
      <Grid container justifyContent="end" mt="10px">
        <Button
          variant="outlined"
          color="secondary"
          style={{
            marginRight: 10,
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          style={{
            justifySelf: "flex-end",
          }}
          variant="contained"
          onClick={handleSave}
        >
          {!isEdit ? "Salvar" : "Editar item"}
        </Button>
      </Grid>
    </GenericModal>
  );
};

export default ModalItemForm;
