import { Box, Grid, Paper } from "@mui/material";
import { FiltroAno, FiltroParcela, FiltroPlano } from "app/shared/components";

const FiltrosBloqueio = () => {
  return (
    <Box component={Paper} p="20px">
      <Grid container  spacing={1}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <FiltroPlano />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <FiltroParcela />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <FiltroAno />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FiltrosBloqueio;
