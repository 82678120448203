import {
  ListaVazia,
  StyledTableContainer,
  TableCellHover,
} from "app/shared/components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Paginacao } from "app/shared/components";
import ModalEdicaoCredito from "../ModalEdicaoCredito";
import ChipSituacao from "../ChipSituacao";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useIsFirstRender, useService } from "app/shared/hooks";
import { LoaderComponent } from "app/shared/components";
import { useFormat } from "app/shared/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getFiltrosSelecionados } from "app/shared/helpers";
import { buscaParcelasSemPlano } from "app/services/parcela";
import Theme from "../../../../app/shared/utils/Theme";
import { buscaRecursos } from "app/services/recurso";
import { setPaginaAtualListagemRecursos } from "app/redux/reducers/recursoSlice";

const PAGE_LIMIT = 5;
const TableRecursos = () => {
  const isFirstRender = useIsFirstRender();
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);
  const recursoSelector = useSelector(
    (state) => state.recurso
  );
  const mostraSomenteSemPlano = recursoSelector.mostraSomenteSemPlano;
  const pagina = recursoSelector.paginaAtualListagemRecursos;
  const [totalItems, setTotalItem] = useState(0);
  const [isMostrarModalEdicaoCredito, setIsMostrarModalEdicaoCredito] =
    useState();
  const [recursoSelecionado, setRecursoSelecionado] = useState();
  const { formataValorParaReais } = useFormat();
  const navigate = useNavigate();

  const handleLoadParcelas = (data) => {
    if (data) {
      setTotalItem(data.last_page);
    }
  };

  const { request: getParcelasReq, response: reponseParcelas } = useService(
    mostraSomenteSemPlano ? buscaParcelasSemPlano : buscaRecursos,
    false,
    handleLoadParcelas,
    () => { },
    { cache: true, cacheKey: "getParcelasSemPlanoAll" }
  );

  const recursosLista = reponseParcelas.data?.data || [];

  const handleBuscaRecursos = (ignoreCache = false) => {
    const paramsPlano = {
      tipo_plano_id: global.filtros.plano,
      escola_id: global.filtros.escola,
      si_id: global.filtros.gre,
      "parcela[ano]": global.ano,
      "parcela[parcela]": global.filtros.parcela,
      status: global.filtros.status,
      page: pagina,
      limit: PAGE_LIMIT,
      with: "tipo_plano,escola,plano_atual,superintendencia,parcela,anexos",
      order: ["parcela_id", "tipo_plano_id"],
    };

    const paramsParcelasSemPlano = {
      ano: global.ano,
      order: ["parcela", "tipo_plano_id"],
      tipo_plano_id: global.filtros.plano,
      escola_id: global.filtros.escola,
      si_id: global.filtros.gre,
      with: "tipo_plano",
      "parcela": global.filtros.parcela,
      page: pagina,
      limit: PAGE_LIMIT,
    };

    const filteredParams = getFiltrosSelecionados(
      mostraSomenteSemPlano ? paramsParcelasSemPlano : paramsPlano
    );
    getParcelasReq(filteredParams, ignoreCache);
  };

  useEffect(() => {
    handleBuscaRecursos();
  }, [pagina, global, mostraSomenteSemPlano]);

  const handleClickCredito = (event, value, recurso) => {
    setRecursoSelecionado(recurso);
    setIsMostrarModalEdicaoCredito(true);
  };

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(setPaginaAtualListagemRecursos(1));
    }
  }, [global.filtros, mostraSomenteSemPlano]);

  const handleClickRecurso = (planoId) => {
    navigate(`/recurso/detalhes?id=${planoId}`);
  };

  const isLoading = reponseParcelas.loading;
  const isEmpty = recursosLista?.length === 0 && !isLoading;

  return (
    <Grid container padding={1}>
      <LoaderComponent
        {...reponseParcelas}
        retry={handleBuscaRecursos}
        message="Buscando recursos"
        errorMessage="Falha ao carregar recursos"
      />
      {!isEmpty && !isLoading && (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography my="5px" variant="h6" color={Theme.colors.black}>
            Total: {`${reponseParcelas?.data?.total || "..."} recursos`}
          </Typography>
        </Box>
      )}
      {recursosLista?.length > 0 && (
        <>
          <StyledTableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>PLANO</TableCell>
                  <TableCell align="left">GRE</TableCell>
                  <TableCell align="left">ESCOLA</TableCell>
                  <TableCell align="left">PARCELA</TableCell>
                  <TableCell align="left">CRÉDITO (R$)</TableCell>
                  <TableCell align="left">CUSTO (R$)</TableCell>
                  <TableCell align="left">SITUAÇÃO (R$)</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recursosLista?.map((recurso, key) => {
                  const shouldHideActions = recurso?.status === undefined;
                  const hasPlano = !mostraSomenteSemPlano;
                  return (
                    <TableRow key={`row_${key}`}>
                      <TableCell align="left">
                        {recurso.tipo_plano.descricao}
                      </TableCell>
                      <TableCell align="left">
                        {mostraSomenteSemPlano
                          ? recurso?.si_descricao
                          : recurso?.superintendencia?.nome}
                      </TableCell>
                      <TableCell align="left">
                        {mostraSomenteSemPlano
                          ? recurso?.nome
                          : recurso?.escola?.nome}
                      </TableCell>
                      <TableCell align="left">
                        {mostraSomenteSemPlano
                          ? typeof recurso?.parcela === "number"
                            ? recurso?.parcela
                            : 0
                          : recurso?.parcela?.parcela}
                      </TableCell>
                      <TableCellHover
                        align="left"
                        hasHover={hasPlano}
                        onClick={(e, value) => {
                          hasPlano && handleClickCredito(e, value, recurso);
                        }}
                        style={{
                          cursor: hasPlano ? "pointer" : "default",
                        }}
                      >
                        {formataValorParaReais(recurso?.credito)}
                      </TableCellHover>
                      <TableCell align="left">
                        {formataValorParaReais(recurso?.valor_total)}
                      </TableCell>
                      <TableCell align="left">
                        <ChipSituacao status={recurso?.status} />
                      </TableCell>
                      <TableCell align="left">
                        {!shouldHideActions && (
                          <Button
                            style={{ backgroundColor: "#0288d1", minWidth: 50 }}
                            onClick={() => handleClickRecurso(recurso?.id)}
                          >
                            <BsEyeFill color="white" size={20} />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Grid width="100%">
            {totalItems > 0 && (
              <Paginacao
                setNumeroPagina={(pagina) => { dispatch(setPaginaAtualListagemRecursos(pagina)) }}
                pagina={pagina}
                totalItems={totalItems}
                style={{ float: "right", padding: 10 }}
              />
            )}
          </Grid>
        </>
      )}
      <ListaVazia isListaVazia={isEmpty} />
      {recursoSelecionado && (
        <ModalEdicaoCredito
          isMostrarModalEdicaoCredito={isMostrarModalEdicaoCredito}
          recurso={recursoSelecionado}
          currentAnexos={recursoSelecionado.anexos}
          onClose={() => {
            setRecursoSelecionado(null);
            setIsMostrarModalEdicaoCredito(false);
          }}
          onSuccess={() => {
            handleBuscaRecursos(true);
            setIsMostrarModalEdicaoCredito(false);
          }}
          valorCreditoAtual={recursoSelecionado?.credito}
        />
      )}
    </Grid>
  );
};

export default TableRecursos;
