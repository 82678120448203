import { Loader } from "./styles";

const SuspenseLoader = () => {
  return (
    <>
      <Loader src="img/logo-plus-title.svg" />
    </>
  );
};

export default SuspenseLoader;
