import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { setEscola, setGre } from 'app/redux/reducers/globalSlice';
import { useApp, useService } from 'app/shared/hooks';
import { buscaGres } from 'app/services/gre';
import LoaderComponent from '../LoaderComponent';

const FiltroGRE = ({ ...rest }) => {
  const [todosGre, setTodosGre] = useState([]);
  const dispatch = useDispatch();
  const filtros = useSelector((state) => state.global.filtros);

  const { user } = useApp();

  const handleLoadGRE = (data) => {
    if (data) {
      setTodosGre(data);
    }
  }

  const {
    request: getGresReq,
    response,
    retry: retryBuscaGRE,
  } = useService(
    buscaGres,
    true,
    handleLoadGRE,
    () => { },
    { cache: true, cacheKey: 'getGRE' }
  );

  useEffect(() => {
    getGresReq({
      com_escolas: 1,
      status: 1,
      escola_categoria: 1,
    });
  }, []);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retryBuscaGRE}
        message={`Buscando ${user.empresa.nome_sup_singular}`}
        errorMessage={`Falha ao carregar ${user.empresa.nome_sup_singular}`}
      />
      {todosGre && todosGre.length > 0 && (
        <FormControl fullWidth>
          <InputLabel shrink={true} id="gre-label-simple-select">
            Filtrar por {user.empresa.nome_sup_singular}
          </InputLabel>
          <Select
            id="gre-label-simple-select"
            labelId="gre-label-simple-select"
            value={filtros.gre || -1}
            label={`Filtrar por ${user.empresa.nome_sup_singular}`}
            notched={true}
            size='small'
            displayEmpty
            onChange={(e) => {
              dispatch(setGre(e.target.value));
              dispatch(setEscola(-1));
            }}
            {...rest}
          >
            <MenuItem value={-1}>
              Todos
            </MenuItem>
            {!!todosGre && todosGre.map((gre) => {
              return (
                <MenuItem key={gre.nome} value={gre.id}>
                  {gre.nome}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default FiltroGRE;