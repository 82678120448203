export const buscaRecursos = (api, props, controller) => {
  return api
    .get("planos", {
      signal: controller.signal,
      params: props,
    })
};

export const buscaRecursoPorId = (api, props, controller) => {
  return api.get(`planos/${props.id}`, {
    signal: controller.signal,
    params: props,
  });
};

export const buscaHistoricoItemRecurso = (api, props, controller) => {
  return api.get(`item_situacoes`, {
    signal: controller.signal,
    params: props,
  });
};