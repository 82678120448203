import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Theme } from "app/shared/utils";
import { FiAlertTriangle } from "react-icons/fi";
const { useState, useEffect } = require("react");
const { default: GenericModal } = require("../GenericModal");

const LoaderModalComponent = ({
  loading,
  error,
  onClose,
  retry,
  message,
  messageError,
}) => {
  const [open, setOpen] = useState(loading);

  useEffect(() => {
    if (loading || error) {
      setOpen(true);
    }
  }, [loading, error]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const getErrorContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
      >
        <FiAlertTriangle size={70} color={Theme.colors.red} />
        <Typography
          textAlign="center"
          mb="10px"
          variant="h5"
          color={Theme.colors.red}
        >
          {messageError || "Erro ao realizar ação, contate um responsável!"}
        </Typography>
        {retry && (
          <Button variant="outlined" onClick={retry}>
            Tentar novamente!
          </Button>
        )}
        <Button variant="text" onClick={() => setOpen(false)}>
          Fechar
        </Button>
      </Box>
    );
  };

  const getLoadingContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
      >
        <CircularProgress size={70} color="info" />
        <Typography
          textAlign="center"
          mt="10px"
          variant="h5"
          color={Theme.colors.blue.dark}
        >
          {message || "Carregando informações"}
        </Typography>
      </Box>
    );
  };

  return (
    <GenericModal
      preventCloseClickOutside
      hasCloseIcon={false}
      size="xs"
      open={open}
      onClose={handleClose}
    >
      {loading && getLoadingContent()}
      {error && getErrorContent()}
    </GenericModal>
  );
};

export default LoaderModalComponent;
