export const buscaPlanosFornecedoresCount = (api, props, controller) => {
  return api.get("planos/counts", {
    signal: controller.signal,
    params: props,
  });
};

export const buscaEntradaSaidaPlanos = (api, props, controller) => {
  return api.get("planos/valores", {
    signal: controller.signal,
    params: props,
  });
};

export const buscaTipoPlanos = (api, props, controller) => {
  return api.get("tipo_planos", {
    signal: controller.signal,
    params: props,
  });
};

export const buscaValoresPorParcelaPlanos = (api, props, controller) => {
  return api.get("planos/valor_by_parcela", {
    signal: controller.signal,
    params: props,
  });
};

export const buscaPlanoStatusCounters = (api, props, controller) => {
  return api.get("planos/group_by_status", {
    signal: controller.signal,
    params: props,
  });
};