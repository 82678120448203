const Theme = {
  colors: {
    blue: {
      default: "#6e95b3",
      dark: "#325d87",
    },
    gray: {
      default: "#e3e4e6",
      ligth: "#8e9094",
      dark: "#8e9094",
    },
    black: "#2a2a2ad1",
    grenn: "#34C38F",
    red: "#E6533C",
    orange: "#EF6C00",
    green: "#559659",
  },
};

export default Theme;
