import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./reducers/dashboardSlice";
import globalSlice from "./reducers/globalSlice";
import recursoSlice from "./reducers/recursoSlice";
import cacheSlice from "./reducers/cacheSlice";



export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    global: globalSlice,
    recurso: recursoSlice,
    cache: cacheSlice,
  },
});

export default store;
