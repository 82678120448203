export const alteraStatusRecursoItem = (api, props, controller) => {
  return api.post("item_situacoes", props, {
    signal: controller.signal,
  });
};

export const alteraCreditoRecurso = (api, props, controller) => {
  return api.post("planos/add_credito", props, {
    signal: controller.signal,
  });
};

export const alteraRecurso = (api, props, controller) => {
  const id = props.get("id")
  const url = id ? `planos/${id}` : `planos`
  return api.post(url, props, {
    signal: controller.signal,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const alteraAnexosItens = (api, props, controller) => {
  return api.post("item_anexos/save_all", props, {
    signal: controller.signal,
  });
};
