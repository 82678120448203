import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";

const TimeLine = ({ items = [] }) => {
  return (
    <Timeline
      sx={{
        padding: "6px 16px 6px 0px",
        backgroundColor: "#ecececcc",
        borderRadius: 2,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {items.map((content) => {
        return (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ margin: 0 }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{content}</TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default TimeLine;
