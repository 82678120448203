import { getUser } from 'app/services/auth/service'
import { redirect } from 'react-router-dom'

const authMiddleware = async () => {
    const user = await getUser()
    const isUserLogged = !!user?.id
    
    if(!isUserLogged){
        return redirect("/auth")
    }

    return null
}

export default authMiddleware