import { ROLES } from "app/shared/constants";
import useApp from "../useApp";

const useRole = () => {
  const { user } = useApp();
  const isRede = user?.perfil === ROLES.PERFIL_GESTOR;
  return {
    isRede,
  };
};

export default useRole;
