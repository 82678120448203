import { useSelector } from "react-redux";

const useApp = () => {
  const {user, ano} = useSelector((state) => state.global);

  return {
    user,
    ano
  };
};

export default useApp;
