export const buscaParcelas = (api, props, controller) => {
    return api
      .get("parcelas", {
        signal: controller.signal,
        params: props
      })
  };

  
export const buscaParcelasSemPlano = (api, props, controller) => {
    return api
      .get("parcelas/escolas_parcelas_sem_plano", {
        signal: controller.signal,
        params: props
      })
  };

export const buscaParcelasPlanoAtual = (api, props, controller) => {
  return api
    .get("parcelas/parcelas_plano_atual", {
      signal: controller.signal,
      params: props
    })
};
  
  

  