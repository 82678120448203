export const areArraysIdenticalById = (array1, array2) => {
    // Se os arrays têm comprimentos diferentes, eles não são idênticos
    if (array1.length !== array2.length) return false;

    // Extrai os IDs de ambos os arrays
    const ids1 = array1.map(obj => obj.id).sort();
    const ids2 = array2.map(obj => obj.id).sort();

    // Verifica se todos os IDs são iguais
    return ids1.every((id, index) => id === ids2[index]);
}