import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { formatMoney } from "app/shared/helpers";
import { Theme } from "app/shared/utils";
import Chart from "react-apexcharts";
import { TbChartBarOff } from "react-icons/tb";

const BarChart = ({
  entradas = [],
  saidas = [],
  parcelas = [],
  isLoading = false,
  loadingMessage = "Gerando gráfico",
}) => {
  const isEmpty = entradas.length === 0 && saidas.length === 0;
  const numberOfParcelas = entradas?.length;
  const labelParcelas = parcelas.map((parcela, index) => `${parcela}ª parcela`);
  const options = {
    series: [
      {
        color: "#2a902f",
        name: "Entrada (R$)",
        data: entradas,
      },
      {
        color: "#fb5b2f",
        name: "Saída (R$)",
        data: saidas,
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -11,
      style: {
        fontSize: "9px",
        colors: ["#000"],
      },
      formatter: function (val, opt) {
        return formatMoney(val);
      },
    },
    grid: {
      show: false,
    },
    legend: {
      position: "top",
      markers: {
        width: 35,
        height: 12,
      },
      itemMargin: {
        horizontal: 30,
        vertical: 0,
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => formatMoney(value),
      },
    },
    xaxis: {
      categories: labelParcelas,
    },
    yaxis: {
      show: false,
    },
  };

  if (isLoading) {
    return (
      <Grid
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems='center'
        flexDirection="column"
      >
        <CircularProgress size={60} />
        <Typography mt="10px">{loadingMessage}</Typography>
      </Grid>
    );
  }
  if (isEmpty) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <TbChartBarOff size={60} color={Theme.colors.black} />
        <Typography variant="h5" color={Theme.colors.black}>
          Não há dados a serem exibidos
        </Typography>
      </Box>
    );
  }

  return (
    <Chart
      type="bar"
      width="100%"
      height="240"
      series={options.series}
      options={options}
    />
  );
};

export default BarChart;
