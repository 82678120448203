import { FooterContainer } from "./styles";
import packageInfo from "../../../../../package.json";
import { Typography } from "@mui/material";

const VERSAO_WEB = packageInfo.version;
const LayoutFooter = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <FooterContainer>
      <small>Copyright &copy; {currentYear} Mobimark</small>

      <small
        style={{
          position: "absolute",
          left: 10
        }}
      >
        Versão {VERSAO_WEB}
      </small>
    </FooterContainer>
  );
};

export default LayoutFooter;
