import { TableCell, TableContainer } from "@mui/material";
import { Theme } from "app/shared/utils";
import styled, { css } from "styled-components";

export const StyledTableContainer = styled(TableContainer)`
  border-radius: 5px;
  thead {
    background-color: #6e95b3;
    th {
      color: white !important;
    }
  }

  tbody {
    font-size: 14px;
  }
`;

const cellHoverStyle = css`
  &:hover {
    background-color: ${Theme.colors.gray.default};
  }
`;

export const TableCellHover = styled(TableCell)`
  ${(props) => props.hasHover && cellHoverStyle}
`;
