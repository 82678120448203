import { Chip } from "@mui/material";
import { RecursoItemStatus } from "app/shared/constants";

const ChipSituacao = ({ status, size = "medium" }) => {
  const situacoes = [
    {
      label: "DEFERIDO",
      color: "#559659",
      status: RecursoItemStatus.DEFERIDO,
    },
    {
      label: "REENVIADO",
      color: "#1e88e5",
      status: RecursoItemStatus.REENVIADO,
    },
    {
      label: "INDEFERIDO",
      color: "#de6363",
      status: RecursoItemStatus.INDEFERIDO,
    },
    {
      label: "CRIADO",
      color: "#000000",
      status: RecursoItemStatus.CRIADO,
    },
  ];

  const actualSituacao = situacoes.find(
    (situacaoObj) => situacaoObj.status === status
  );

  return (
    actualSituacao && (
      <Chip
        size={size}
        label={actualSituacao.label}
        color="primary"
        style={{
          backgroundColor: "transparent",
          borderColor: actualSituacao.color,
          color: actualSituacao.color,
          fontWeight: "bold",
        }}
        variant="outlined"
      />
    )
  );
};

export default ChipSituacao;
