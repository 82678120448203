import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recursoSelecionado: null,
  mostraSomenteSemPlano: false,
  itemRecursoSelecionado: null,
  paginaAtualListagemRecursos: 1,
  paginaAtualListagemRecursosBoqueio: 1,
  anoFiltro: -1,
};

export const recursoSlice = createSlice({
  name: "recurso",
  initialState: initialState,
  reducers: {
    setRecursoSelecionado: (state, action) => {
      state.recursoSelecionado = action.payload;
    },
    setItemRecursoSelecionado: (state, action) => {
      state.itemRecursoSelecionado = action.payload;
    },
    setMostraSomenteSemPlano: (state, action) => {
      state.mostraSomenteSemPlano = action.payload;
    },
    setItensRecursoSelecionado: (state, action) => {
      state.recursoSelecionado = {
        ...state.recursoSelecionado,
        itens: action.payload
      };
    },
    setPaginaAtualListagemRecursos: (state, action) => {
      state.paginaAtualListagemRecursos = action.payload;
    },
    setPaginaAtualListagemRecursosBloqueio: (state, action) => {
      state.paginaAtualListagemRecursosBoqueio = action.payload;
    },
    setAnoFiltro: (state, action) => {
      state.anoFiltro = action.payload;
    },
  },
});

export const {
  setRecursoSelecionado,
  setItemRecursoSelecionado,
  setItensRecursoSelecionado,
  setMostraSomenteSemPlano,
  setPaginaAtualListagemRecursos,
  setPaginaAtualListagemRecursosBloqueio,
  setAnoFiltro
} = recursoSlice.actions;

export default recursoSlice.reducer;
