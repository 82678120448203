import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setStatus } from "app/redux/reducers/globalSlice";
import { RecursoStatus } from "app/shared/constants";
import { useDispatch, useSelector } from "react-redux";

const FiltroStatus = () => {
  const filtros = useSelector((state) => state.global.filtros);
  const mostraSomenteSemPlano = useSelector(
    (state) => state.recurso.mostraSomenteSemPlano
  );
  const dispatch = useDispatch();

  const todosStatus = [
    {
      nome: "Todos",
      value: -1,
    },
    {
      nome: "Pendente",
      value: RecursoStatus.PENDENTE,
    },
    {
      nome: "Em Análise",
      value: RecursoStatus.EM_ANALISE,
    },
    {
      nome: "Deferido",
      value: RecursoStatus.DEFERIDO,
    },
    {
      nome: "Indeferido",
      value: RecursoStatus.INDEFERIDO,
    },
  ];

  return (
    <FormControl fullWidth pr={1}>
      <InputLabel shrink={true} id="demo-simple-select-label-status">
        Filtrar por status
      </InputLabel>
      <Select
        labelId="demo-simple-select-label-status"
        id="demo-simple-select-status"
        value={filtros.status === "" ? -1 : filtros.status}
        notched={true}
        size="small"
        displayEmpty
        disabled={mostraSomenteSemPlano}
        label="Filtrar por status"
        onChange={(e) => {
          dispatch(setStatus(e.target.value));
        }}
      >
        {!!todosStatus &&
          todosStatus.map((status) => {
            return (
              <MenuItem key={status.nome} value={status.value}>
                {status.nome}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default FiltroStatus;
