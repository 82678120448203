import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { buscaGroupedCnpjs } from "app/services/aplicacao";
import { LoaderComponent, NumberFormat, QuantNumberFormat } from "app/shared/components";
import { formatTypedCNPJ } from "app/shared/helpers";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { validarCNPJ } from "../../utils";

const CusteioSection = ({ value, onChange, setError }) => {
  const isValid = (() => {
    const hasCusto = !!value.custo_capital || !!value.custo_custeio;
    const hasInfos = value.quantidade > 0;
    return hasCusto && hasInfos;
  })();

  const {
    request: getGroupedCnpjsReq,
    response: responseCNPJs,
    retry: retryGroupedCnpjs,
  } = useService(buscaGroupedCnpjs);

  const cnpjOptions =
    responseCNPJs?.data?.map((fornecedor) => formatTypedCNPJ(fornecedor.cnpj_fornecedor)) || [];
  const cnpjFormatado = value.cnpj_fornecedor?.replace(/\D/g, '');
  const isCNPJValid = validarCNPJ(cnpjFormatado)
  const existeCNPJNaLista = isCNPJValid || !cnpjFormatado;
  
  useEffect(() => {
    getGroupedCnpjsReq({ limit: 0 });
  }, []);

  useEffect(() => {
    setError(!existeCNPJNaLista ? "Necessário informar um CNPJ válido" : undefined);
  }, [value.cnpj_fornecedor, cnpjOptions]);

  const borderColor = isValid
    ? `1px solid ${Theme.colors.gray.ligth}`
    : `2px solid ${Theme.colors.red}`;

  return (
    <LoaderComponent
      {...responseCNPJs}
      retry={retryGroupedCnpjs}
      message="Buscando lista de CNPJs"
      errorMessage="Falha ao carregar CNPJs"
    >
      <Grid border={borderColor} borderRadius={1} p={1} spacing={1} mb="10px">
        <Grid container direction="row" mb="10px">
          <FiAlertCircle color={Theme.colors.blue.dark} />
          <Typography
            ml="5px"
            variant="body2"
            fontWeight="bold"
            color={Theme.colors.blue.dark}
          >
            Ao menos um custo deve ser informado
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography
              variant="body2"
              fontSize={12}
              fontWeight="bold"
              color={Theme.colors.blue.dark}
            >
              CUSTO CAPITAL
            </Typography>
            <TextField
              size="small"
              value={value.custo_capital}
              onChange={(e) => onChange({ custo_capital: e.target.value })}
              fullWidth
              placeholder="R$ 0,00"
              InputProps={{
                inputComponent: NumberFormat,
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography
              variant="body2"
              fontWeight="bold"
              fontSize={12}
              color={Theme.colors.blue.dark}
            >
              CUSTO CUSTEIO
            </Typography>
            <TextField
              size="small"
              value={value.custo_custeio}
              onChange={(e) => onChange({ custo_custeio: e.target.value })}
              fullWidth
              placeholder="R$ 0,00"
              InputProps={{
                inputComponent: NumberFormat,
              }}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Typography
              variant="body2"
              fontWeight="bold"
              fontSize={12}
              color={Theme.colors.blue.dark}
            >
              QUANTIDADE
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={value.quantidade}
              onChange={(e) => onChange({ quantidade: e.target.value })}
              InputProps={{
                inputComponent: QuantNumberFormat,
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              variant="body2"
              fontWeight="bold"
              fontSize={12}
              color={Theme.colors.blue.dark}
            >
              CNPJ DO FORNECEDOR
            </Typography>
            <Autocomplete
              disablePortal
              options={cnpjOptions}
              fullWidth
              size="small"
              value={formatTypedCNPJ(value.cnpj_fornecedor)}
              inputValue={formatTypedCNPJ(value.cnpj_fornecedor)}
              onInputChange={(e, value) => {
                onChange({ cnpj_fornecedor: value });
              }}
              onChange={(e, value) => {
                onChange({ cnpj_fornecedor: value });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </LoaderComponent>
  );
};

export default CusteioSection;
